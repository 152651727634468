body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Hero section*/
/*header*/
.hero{
  display: flex;
  justify-content: space-between;
}
.left-h{
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
.right-h{
  flex: 1;
  position: relative;
  background-color: var(--orange);
}
.header{
  display: flex;
  justify-content: space-between;
}
.logo{
  width: 10rem;
  height: 3rem;
}
.header-menu{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header-menu>li{
  cursor: pointer;
}
.header-menu>li:hover{
  color: var(--orange);
}

/*left-h content*/
.ad{
  margin-top: 4rem;
  background-color: #363d42;
  width: fit-content;
  padding: 20px 13px;
  border-radius: 4rem;
  text-transform: uppercase;
  color: white;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.ad>div{
  background-color: var(--orange);
  position: absolute;
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 0;
}
.ad>span{
  z-index: 1;
}

/*hero-text*/

.hero-text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 1px white;
}
.hero-text>div:nth-of-type(3){
  text-transform: none;
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 1px;
  width: 80%;
}
/*figures*/
.figures{
  display: flex;
  gap: 2rem;
}
.figures>div{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.figures>div>span:nth-child(1){
  color: white;
  font-size: 2rem;
}
.figures>div>span:nth-child(2){
  color: var(--gray);
  text-transform: uppercase;
}
/*hero-buttons*/
.hero-btn{
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover{
  cursor: pointer;
}
.hero-btn>:nth-child(1){
  color: white;
  background-color: var(--orange);
  width: 8rem;
}
.hero-btn>:nth-child(2){
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--orange);
}

/*right-h*/
.right-h>.btn{
  position: absolute;
  right: 3rem ;top: 2rem;color: black;
}
.heart-rate{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: flex-start;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.heart-rate:nth-child(2){
  color: var(--gray);
}
.heart-rate:nth-child(3){
  color: white;
  font-size: 1.5rem;
}
.heart-rate>img{
  width: 2rem;
}

/*hero-image*/
.hero-img{
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}
.hero-img-back{
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}


/*calories*/
.calories{
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 34rem;
  right: 42rem;
}
.calories>img{
  width: 3rem;
}
.calories>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calories>div:nth-child(1){
  color: var(--gray);
}
.calories>div:nth-child(2){
  color: white;
  font-size: 1.5rem;
}


/*programs*/
.programs{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.programs-header{
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 2.5rem;
  justify-content: center;
  color: white;
  font-style: italic;
  text-transform: uppercase;
}

/*categories*/

.programs-categories{
  display: flex;
  gap: 1rem;
}
.category{
  display: flex;
  flex-direction: column;
  background-color: gray;
  gap: 1rem;
  padding: 2rem;
  color: white;
  justify-content: space-between;
}
.category>:nth-child(1){
  width: 2rem;
  height: 2rem;
  fill: white;
}
.category>:nth-child(2){
  font-size: 1rem;
  font-weight: bold;
}
.category>:nth-child(3){
 font-size: 0.9rem;
 line-height: 25px;
}
.join-now{
  display: flex;
  gap: 2rem;
  align-items: center;
}
.join-now>img{
  width: 1rem;
}
.category:hover{
  background: var(--planCard);
  cursor: pointer;
}

/*reasons*/

.reasons{
  display: flex;
  padding: 0 7rem;
  gap: 12rem;
  
}
.left-r{
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}
.left-r>img{
  object-fit: cover;
}
.left-r>:nth-child(1){
  grid-row: 1/3;
  width: 12rem;
  height: 28rem;
}
.left-r>:nth-child(2){
  grid-column: 2/4;
  width: auto;
  height: 16rem;
}
.left-r>:nth-child(3){
  grid-row: 2;
  grid-column: 2/3;
  width: 14rem;
}
.left-r>:nth-child(4){
  grid-row: 2;
  grid-column: 3/4;
  width: 10rem;
}

.right-r{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  gap: 1rem;
}

.right-r>span{
  font-weight: bold;
  color: var(--orange);
}
.right-r>div{
  font-weight: bold;
  font-size: 3rem;
  color: white;
}
.details-r{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.details-r>div{
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}
.details-r>div>img{
  width: 2rem;
  height: 2rem;
}
.partners{
  display: flex;
  gap: 1rem;
}
.partners>img{
  width: 1.5rem;
}

/*plans*/

.programs-h{
  display: flex;
  gap: 4rem;
  font-weight: bold;
  font-size: 2.5rem;
  justify-content: center;
  color: white;
  font-style: italic;
  text-transform: uppercase;
  margin-right: 3rem;
}


/*plans*/
.plans-c{
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.plans{
  margin-top: 4rem;
  display: flex;
  gap: 5rem;
  align-items: center;
  justify-content: center;
}
.plan{
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}

.plan:nth-child(2){
  background: var(--planCard);
  transform: scale(1.1);
}
.plan:nth-child(1):hover{
  background: var(--planCard);
  transform: scale(1.1);
  transition: 0.3s;
}
.plan:nth-child(3):hover{
  background: var(--planCard);
  transform: scale(1.1);
  transition: 0.3s;
}
.plan:nth-child(1):hover+.plan:nth-child(2){
  background: var(--caloryCard);
  transform: scale(1);
  transition: 0.3s;
}
div:has(.plan:nth-child(3):hover) .plan:nth-child(2){
  background: var(--caloryCard);
  transform: scale(1);
  transition: 0.3s;
}
div:has(.plan:nth-child(3):hover) .plan:nth-child(2)>button{
  color: black;
}
.plan:nth-child(1):hover+.plan:nth-child(2)>button{
  color: black;
}

.plan>svg{
  fill: var(--orange);
  height: 2rem;
  width: 2rem;
}

.plan>:nth-child(2){
  font-size: 1rem;
  font-weight: bold;
}
.plan>:nth-child(3){
  font-size: 3rem;
  font-weight: bold;
}
.plan>:nth-child(5){
  font-size: 0.8rem;
}
.plan>:nth-child(5) img{
  width: 0.8rem;
  padding-left: 0.7rem;
}
.features{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.feature>img{
  width: 1rem;
}
.plan:nth-child(2)>svg{
 fill: white;
}
.plan:nth-child(2)>button{
  color: var(--orange);
 }

 /*testimonials*/

 .testimonials{
  display: flex;
  gap: 1rem;
  padding: 0 5rem;
 }
 .left-t{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  text-transform: uppercase;
  color: white;
 }
 .left-t>:nth-child(1){
  color: var(--orange);
  font-weight: bold;
 }
 .left-t>:nth-child(2),
 .left-t>:nth-child(3){
  font-size: 3rem;
  font-weight: bold;
 }
 .left-t>:nth-child(4){
   text-transform: none;
   text-align: justify;
   letter-spacing: 2px;
   line-height: 40px;
 }

 .right-t{
  flex: 1;
  position: relative;
 }
 .right-t>img{
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
 }
 .right-t>:nth-child(1){
  position: absolute;
  width: 17rem;
  height: 20rem;
  right: 9rem;
  top: 0.9rem;
  border: 2px solid var(--orange);
  background-color: transparent;
 }
 .right-t>:nth-child(2){
  position: absolute;
  width: 17rem;
  height: 20rem;
  right: 7rem;
  top: 3rem;
  background: var(--planCard);
 }
 .arrows{
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
 }
 .arrows>img{
  width: 1.5rem;
  cursor: pointer;
 }

 /*join*/

 .join{
  display: flex;
  padding: 0 5rem;
  gap: 15rem;
 }
 .left-j{
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  margin-top: 3rem;
 }

 .left-j::after{
  content: "";
  position: absolute;
  width: 13rem;
  height: 0.3rem;
  background: var(--planCard);
  top: -1rem;
  border-radius: 40%;
 }
 
 .email-c{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: var(--caloryCard);
  padding: 2rem;
  margin-top: 20px;
  height: 15rem;
 }
 .email-c>input{
  background-color: var(--darkGrey);
  outline: none;
  border: none;
  color: var(--lightgray);
  padding: 1.5rem;
 }
 ::placeholder{
  color: var(--lightgray);
 }
 .btn-j{
  background-color: var(--orange);
  color: white;
  margin-top: 5px;
 }

 .right-j{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: -50px;
 }
#m{
  margin-top: 10px;
}


/*footer*/
.footer-c{
  position: relative;
}
.footer-c>hr{
  border: 1px solid var(--lightgray);
}
.footer{
  display: flex;
  padding: 2rem 2rem;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}
.social{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  cursor: pointer;
 height: 20rem;

}
.footer>img{
  width: 2rem;
  height: 2rem;
}
.logo-f>img{
  width: 10rem;
}


/*blur effect*/

.blur{
  background: rgba(253, 123, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}
.blur-hero{
  width:22rem;
  height: 30rem;
  left: 0;
}
.blur-plan1{
  width:22rem;
  height: 30rem;
  left: 10%;

}
.blur-plan2{
  width:22rem;
  height: 30rem;
  right: 10%;
}

.blur-f1{
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: var(--planCard);
  left: 15%;
  bottom: 0%;
}
.blur-f2{
  right: 15%;
  bottom: 0%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: var(--planCard);
}

.exit{
  display: none;
}




/*media query*/

@media screen and (max-width:425px) {
  .hero{
    flex-direction: column;
  }
  .blur-hero{
    width: 14rem;
  }
  .ad{
    margin-top: 0;
    align-self: center;
    transform: scale(0.9);
    font-size: small;
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text>div:nth-of-type(3){
    font-size: 17px;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-btn{
    justify-content: center;
  }
  .heart-rate{
    position: relative;
    top: 1rem;
    left: 2rem;
  }
  .right-h{
    background:none;
  }
  .hero-img{
    position: relative;
    width: 15rem;
    height: 18rem;
    top: 1rem;
    left: 6rem;
  }
  .hero-img-back{
    position: absolute;
    top: 4rem;
    right: -10rem;
    z-index: -1;
    width: 10rem;
  }
  .calories{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 0rem;
    right: 0rem;
  }
  .calories>img{
    width: 3rem;
  }

  /*programs*/
  .programs-header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
  }
  .programs-categories{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  

  /*reasons*/
  .reasons{
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    gap: 3rem;
    
  }
  .left-r{
    gap: 0.5rem;
  }
  .left-r>:nth-child(1){
    width: 7rem;
    height: 18rem;
  }
  .left-r>:nth-child(2){
    width: 16rem;
    height: 9rem;
  }
  .left-r>:nth-child(3){
    width: 9rem;
    height: 8.4rem;
  }
  .left-r>:nth-child(4){
    width: 6.5rem;
    height: 8.4rem;
  }
  
  .right-r>div{
    font-size: 2rem;
  }
  .details-r>div>img{
    width: 1rem;
    height: 1rem;
  }
  .partners{
    display: flex;
    gap: 1rem;
  }
  .partners>img{
    width: 1rem;
  }

  /*plans*/

 
.plans{
  flex-direction: column;
  gap: 3rem;
}
.plan{
  gap: 1rem;
  padding: 1rem;
  width: 12rem;
}

.plan>:nth-child(3){
  font-size: 2rem;
}
.blur-plan1{
  width:12rem;
  height: 15rem;
  left: 0%;

}
.blur-plan2{
  width:12rem;
  height: 15rem;
  right: 0%;
}
.programs-h{
  flex-direction: column;
  gap: 1rem;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

 /*testimonials*/

 .testimonials{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 3rem;
 }
 .left-t{
  gap: 1rem;
}

 .left-t>:nth-child(2),
 .left-t>:nth-child(3){
  font-size: 2rem;
 }
 .left-t>:nth-child(4){
   letter-spacing: 1px;
   line-height: 30px;
 }
 .right-t{
  flex: 1;
  position: relative;
 }
 .right-t>img{
  position: absolute;
  width: 14rem;
  height: 17rem;
  right: 3rem;
  top: 2rem;
 }
 .right-t>:nth-child(1){
  position: absolute;
  width: 14rem;
  height: 17rem;
  right: 4rem;
}
 .right-t>:nth-child(2){
  position: absolute;
  width: 14rem;
  height: 17rem;
  right: 2rem;
  top: 3rem;
 }
 .arrows{
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 2rem;
  left: 14rem;
 }

 /*join*/
 .join{
  flex-direction: column;
  gap: 7rem;
 }
 .left-j{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-top: 20rem;
  margin-left: 20px;
 }

 .left-j::after{
  left: 0rem;
 }

 /*header*/

 .header{
  display: flex;
  justify-content: space-between;
  position: relative;
}
.logo{
  width: 10rem;
  height: 3rem;
}
.header-menu{
  display: flex;
  list-style: none;
  flex-direction: column;
  gap: 0.5rem;
  color: white;
  background: var(--darkGrey);
  padding: 1.5rem;
  position: absolute;
  right: 0;
  z-index: 9;
  
}
.header_list{
  display: flex;
  gap: 0rem;
  flex-direction: column;
}


}


